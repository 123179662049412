<template>
  <div class="edit_box">
    <div class="title_box">
      <div class="edit_title">カード情報入力</div>
      <div class="right">
        <div class="edit_submit" @click="submit">項目を保存</div>
      </div>
    </div>

    <div v-if="use_count > 1">
      <div class="subject">デフォルト設定</div>
      <div class="input_group">
        <div class="input_box">
          <div>
            <label><input type="radio" v-model="edit_data.default_method" value="" />指定しない</label>
          </div>
          <div v-for="(value, key, index) in use_payment" :key="index">
            <label v-if="key == 'univapay'"><input type="radio" v-model="edit_data.default_method" :value="key" />{{
              edit_data.title.univapay }}</label>
            <label v-if="key == 'bank'"><input type="radio" v-model="edit_data.default_method" :value="key" />{{
              edit_data.title.bank }}</label>
          </div>
        </div>
      </div>
    </div>

    <div v-if="edit_data.payment_method == 'univapay'">
      <div class="subject">UnivaPay</div>
      <div class="input_group">
        <div class="input_box">
          <label for="subject">タイトル</label>
          <input id="subject" type="text" v-model="edit_data.subject" />
        </div>
        <div class="input_box">
          <label>金額(円)
            <input type="text" v-model="edit_data.amount" /></label>
        </div>
        <div class="input_box">
          <label>トークン
            <input type="text" v-model="edit_data.univapay_token" /></label>
        </div>
        <div class="input_box">
          <label>シークレット
            <input type="text" v-model="edit_data.univapay_secret" /></label>
        </div>
      </div>
    </div>

    <div v-if="edit_data.payment_method == 'select'">
      <div class="subject">UnivaPay</div>
      <div class="input_group">
        <div class="input_box">
          <input id="use_univapay" type="checkbox" v-model="edit_data.use.univapay" value="univapsy" />
          <label for="use_univapay">UnivaPayを使用する</label>
        </div>
        <div class="input_box">
          <label for="subject">支払方法選択肢名称</label>
          <input id="subject" type="text" v-model="edit_data.title.univapay" />
        </div>
        <div class="input_box">
          <label for="subject">タイトル</label>
          <input id="subject" type="text" v-model="edit_data.subject" />
        </div>
        <div class="input_box">
          <label>金額(円)
            <input type="text" v-model="edit_data.amount" /></label>
        </div>
        <div class="input_box">
          <label>トークン
            <input type="text" v-model="edit_data.univapay_token" /></label>
        </div>
        <div class="input_box">
          <label>シークレット
            <input type="text" v-model="edit_data.univapay_secret" /></label>
        </div>
      </div>
      <div class="subject">銀行振込</div>
      <div class="input_group">
        <div class="input_box">
          <input id="use_bank" type="checkbox" v-model="edit_data.use.bank" value="bank" />
          <label for="use_bank">銀行振込を使用する</label>
        </div>
        <div class="input_box">
          <label for="subject">支払方法選択肢名称</label>
          <input id="subject" type="text" v-model="edit_data.title.bank" />
        </div>
        <div class="input_box">
          <label for="subject">タイトル</label><br />
          <input id="subject" type="text" v-model="edit_data.bank_subject" />
        </div>
        <div class="input_box">
          <label>金額(円)
            <input type="text" v-model="edit_data.bank_amount" /></label>
        </div>
        <div class="input_box">
          <label>文面</label><br />
          <textarea v-model="edit_data.bank_notice"></textarea>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'EditEmailAndTel',
  props: ['chat'],
  components: {
  },
  data() {
    return {
      edit_data: {},
    }
  },
  computed: {
    use_payment: function () {
      let use = {}
      for (let key in this.edit_data.use) {
        if (this.edit_data.use[key]) {
          use[key] = true
        }
      }
      return use
    },
    use_count: function () {
      let count = 0
      for (let key in this.edit_data.use) {
        if (this.edit_data.use[key]) {
          count++
        }
      }
      return count
    },
    use_first: function () {
      let payment_method = ''
      for (let key in this.edit_data.use) {
        if (this.edit_data.use[key]) {
          payment_method = key
        }
      }
      return payment_method
    },
  },
  watch: {
  },
  mounted: function () {
    this.edit_data = this.chat.data
    if (this.edit_data.payment_method == 'univapay') {
      this.edit_data.payment_method = 'select'
      this.edit_data.use = { univapay: true, bank: false }
      this.edit_data.use_count = 1
      this.edit_data.title = { univapay: 'クレジットカード', bank: '銀行振込', }
      this.edit_data.bank_subject = '銀行振込'
      this.edit_data.bank_notice = ''
      this.edit_data.bank_amount = this.edit_data.amount
    }
  },
  methods: {
    submit: function () {
      let data = this.edit_data;
      this.$emit("submit", data);
    },
    dummy() {
      console.log(this.edit_data)
    },
  }
}
</script>

<style scoped>
.edit_box {
  margin: 20px;
}

.title_box {
  position: relative;
  width: 100%;
  height: 30px;
  margin-bottom: 20px;
}

.edit_title {
  position: absolute;
  color: white;
  font-weight: 700;
  line-height: 30px;
  font-size: 18px;
}

.right {
  position: absolute;
  height: 30px;
  right: 0;
}

.edit_submit {
  background-color: #42B5D1;
  border-color: #42B5D1;
  color: white;
  line-height: 24px;
  padding: 3px 6px;
  font-size: 14px;
  border-radius: 8px;
}

.input_group {
  overflow: hidden;
  border: 0 solid #ffb300;
  text-align: left;
  background-color: white;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 10px;
  margin-bottom: 20px;
}

.input_box {
  border: 1px solid #ffb300;
  padding: 10px 5px;
  position: relative;
}

.input_box label {
  margin-bottom: 5px;
}

.input_box input[type="text"],
.input_box textarea {
  width: calc(100% - 10px);
  font-size: 18px;
}

.input_box textarea {
  resize: vertical;
  height: 10em;
}

.subject {
  color: white;
}
</style>
